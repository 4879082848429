import { lazy } from 'react';

export const XMPL = lazy(() => import('./XMPL'));
export const Router = lazy(() => import('./Router'));

// V2 Pages
export const DelegateV2 = lazy(() => import('./DelegateV2'));
export const Lend = lazy(() => import('./Lend'));
export const AccountV2 = lazy(() => import('./AccountV2'));
export const BorrowV2 = lazy(() => import('./BorrowV2'));
export const MPLV2 = lazy(() => import('./MPLV2'));
