import { BigNumber } from '@ethersproject/bignumber';
import { AddEthereumChainParameter } from '@web3-react/types';

export type Network = 'sepolia' | 'base-mainnet' | 'mainnet' | 'localhost';
export type Project =
  | 'sepolia-dev'
  | 'sepolia-prod'
  | 'base-mainnet-prod'
  | 'mainnet-dev'
  | 'mainnet-prod'
  | 'localhost';

export const NETWORK = (process.env.REACT_APP_NETWORK as Network) || 'localhost';
export const PROJECT = (process.env.REACT_APP_PROJECT as Project) || 'localhost';

export const getNetworkEnvironment = (): { network: string; environment: string } => {
  const networkRegex = PROJECT.match(/(mainnet|sepolia)/);
  const environmentRegex = PROJECT.match(/(dev|prod|localhost)/);

  const obj = {
    network: '',
    environment: '',
  };

  if (networkRegex) {
    switch (networkRegex[0]) {
      case 'sepolia':
        obj.network = 'sepolia';
        break;
      case 'mainnet':
        obj.network = 'mainnet';
        break;
    }
  }

  if (environmentRegex) {
    switch (environmentRegex[0]) {
      case 'dev':
        obj.environment = 'dev';
        break;
      case 'prod':
        obj.environment = 'prod';
        break;
      case 'localhost':
        obj.environment = 'localhost';
        break;
    }
  }

  return obj;
};

export const isProtocolPaused = process.env.REACT_APP_PROTOCOL_PAUSED
  ? JSON.parse(process.env.REACT_APP_PROTOCOL_PAUSED)
  : false;

export const isBaseDeployment = ['base-mainnet-prod'].includes(PROJECT);

export const webappVersion = process.env.REACT_APP_SENTRY_RELEASE || 'localhost';

const etherscanUrls: Record<Network, string> = {
  sepolia: 'https://sepolia.etherscan.io',
  'base-mainnet': 'https://basescan.org',
  mainnet: 'https://etherscan.io',
  localhost: 'https://etherscan.io',
};
export const etherscanUrl = etherscanUrls[NETWORK] || etherscanUrls.mainnet;

const gnosisSafeApiBaseUrls: Record<Network, string> = {
  sepolia: 'https://safe-transaction-sepolia-testnet.safe.global',
  'base-mainnet': 'https://safe-transaction-base.safe.global',
  mainnet: 'https://safe-transaction-mainnet.safe.global',
  localhost: 'https://safe-transaction-mainnet.safe.global',
};
export const gnosisSafeApiUrl = `${
  gnosisSafeApiBaseUrls[NETWORK] || gnosisSafeApiBaseUrls.mainnet
}/api/v1/multisig-transactions`;

type ExtendedEthereumChainParameter = AddEthereumChainParameter & {
  rpcAccessKey?: string;
};

export const NETWORKS_V2: Record<string, ExtendedEthereumChainParameter> = {
  mainnet: {
    chainId: 1,
    chainName: 'mainnet',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: [process.env.REACT_APP_MAINNET_URL || 'https://main-light.eth.linkpool.io'],
  },
  'base-mainnet': {
    chainId: 8453,
    chainName: 'base-mainnet',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: [process.env.REACT_APP_BASE_MAINNET_URL || 'https://mainnet.base.org'],
    rpcAccessKey: process.env.REACT_APP_BASE_MAINNET_RPC_KEY,
  },
  sepolia: {
    chainId: 11155111,
    chainName: 'sepolia',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: [process.env.REACT_APP_SEPOLIA_URL || '???'],
  },
  localhost: {
    chainId: 31337,
    chainName: 'localhost',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['http://localhost:8545'],
  },
};

export const CHAIN_ID = NETWORKS_V2[NETWORK].chainId;
export const RPC_URL = NETWORKS_V2[NETWORK].rpcUrls[0];
export const RPC_ACCESS_KEY = NETWORKS_V2[NETWORK].rpcAccessKey;

export const CHAIN_ID_TO_NETWORK = Object.keys(NETWORKS_V2).reduce((acc, val) => {
  const { chainId, chainName } = NETWORKS_V2[val];
  acc[chainId] = chainName;

  return acc;
}, {} as any);

export const NETWORKS_V2_RPC_URLS = Object.keys(NETWORKS_V2).reduce((acc, val) => {
  const { chainId, rpcUrls } = NETWORKS_V2[val];
  acc[chainId] = rpcUrls[0];

  return acc;
}, {} as any);

// key names for localStorage entries
export const WALLETCONNECT_DEEPLINK_CHOICE = 'WALLETCONNECT_DEEPLINK_CHOICE';
export const WALLETCONNECT_SESSION = 'wc@2:client:0.3//session';
export const WEB3_CONNECT_CACHED_PROVIDER = 'WEB3_CONNECT_CACHED_PROVIDER';
export const WELCOME_POPUP_SEEN = 'WELCOME_POPUP_SEEN';

const INFINITE_APPROVAL_HEX = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff';

export const INFINITE_APPROVAL_OBJ = {
  _hex: INFINITE_APPROVAL_HEX,
  _isBigNumber: true,
};

export const ONE = BigNumber.from(1);
export const TEN = BigNumber.from(10);
export const ONE_HUNDRED = BigNumber.from(100);
export const ZERO = BigNumber.from(0);
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const POLLING_INTERVAL = 1500;
export const XMPL_POLLING_INTERVAL = 3 * POLLING_INTERVAL;
export const SECONDS_IN_MINUTE = 60;
export const SECONDS_IN_DAY = 24 * 60 * 60; // 86,400
export const SECONDS_IN_HOUR = 60 * 60; // 3,600
export const LOAN_EXPIRATION_PERIOD = 10 * SECONDS_IN_DAY; // period in seconds after which an unfunded loan expires (can no longer be funded)

export const RECAPITALIZATION_RATE = 5; // hardwired 5% for MPLv2
// TODO extract decimals to Constants/decimals.ts, in general divide up this file into semantic pieces
// price decimals
export const CHAINLINK_PRICE_DECIMALS = 8;

// token decimals
export const FIAT_DECIMALS = 4;
export const SATOSHI_DECIMALS = 8;
export const STABLECOIN_DECIMALS = 6;
export const WEI_DECIMALS = 18;

// rate decimals
export const APY_RATE_DEC = 18;
export const APY_DECIMALS = 30;
export const FEE_DECIMALS = 6; // v2 fee rates
export const INTEREST_RATE_DECIMALS = 18;
export const INTEREST_RATE_UI_DECIMALS = 4;
export const ISSUANCE_RATE_DECIMALS = 30;
export const OT_ISSUANCE_RATE_DECIMALS = 27;
export const UTILIZATION_RATE_DECIMALS = 30;
export const RATE_DECIMALS = 6;

// percentage decimals
export const ACM_RATIO_DECIMALS = 0;
export const PERCENTAGE_DECIMALS = 2;
export const PERCENTAGE_INPUT_DECIMALS = 4;

// UI decimals
export const DEFAULT_COINS_UI_DECIMALS = 4;
export const MPL_EXPANDED_UI_DECIMALS = 6;
export const PERCENTAGE_UI_DECIMALS = 2;
export const STABLE_COINS_UI_DECIMALS = 2;

export const STABLE_COINS = ['USDC', 'DAI'];

export const CHARACTER_PIXELS_SPACE = 11;

export const SECONDS_PER_DAY = 60 * 60 * 24;
export const MILLISECONDS_PER_DAY = SECONDS_PER_DAY * 1000;

export const DEFAULT_DEADLINE_REFINANCING = SECONDS_PER_DAY * 2; // 2 DAYS
export const ANIMATION_TENSION = 2;

export const INTERCOM_TEST_ID = 'uvuskuxb';
export const INTERCOM_APP_ID = process.env.VITE_INTERCOM_ID || INTERCOM_TEST_ID;

export const GRACE_PERIOD = process.env.REACT_APP_GRACE_PERIOD ? JSON.parse(process.env.REACT_APP_GRACE_PERIOD) : 5; // 5 days grace period by default
export const PAYMENT_INTERVAL = { FORTNIGHTLY: 14, MONTHLY: 30, MONTHLY_AND_HALF: 45, QUARTERLY: 90 };

export const TERM_LENGTH_OPTIONS = ['14', '30', '60', '90', '180', '360', '540', '720'];

export const DATE_FORMAT = {
  day: 'numeric',
  month: 'short',
  year: 'numeric',
};

export const TIME_FORMAT = {
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
};

export const DATE_FORMAT_TIME = {
  ...DATE_FORMAT,
  hour: 'numeric',
  minute: 'numeric',
};

// Typeform ID (set as secrets into Github)
export const TYPEFORM_REQUEST_POOL_ACCESS_ID = process.env.REACT_APP_TYPEFORM_REQUEST_POOL_ACCESS_ID;
export const TYPEFORM_REQUEST_BORROWER_ACCESS_ID = process.env.REACT_APP_TYPEFORM_REQUEST_BORROWER_ACCESS_ID;
export const TYPEFORM_CONTACT_PD_ID = process.env.REACT_APP_TYPEFORM_CONTACT_PD_ID;
export const TYPEFORM_PD_ONBOARDING_ID = process.env.REACT_APP_TYPEFORM_PD_ONBOARDING_ID;
export const REACT_APP_TYPEFORM_KYC_ID = process.env.REACT_APP_TYPEFORM_KYC_ID;
export const REACT_APP_TYPEFORM_ACCREDITATION_ID = process.env.REACT_APP_TYPEFORM_ACCREDITATION_ID;
export const REACT_APP_TYPEFORM_MLA_ID = process.env.REACT_APP_TYPEFORM_MLA_ID;
export const REACT_APP_TYPEFORM_TAX_ID = process.env.REACT_APP_TYPEFORM_TAX_ID;
export const REACT_APP_TYPEFORM_REQUEST_POOL_INTEREST_ID = process.env.REACT_APP_TYPEFORM_REQUEST_POOL_INTEREST_ID;

// WalletConnect
export const WALLET_CONNECT_PROJECT_ID =
  process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID || '84c765e293937d93a74ccb5282bcdc6c';

// PoolV2 addresses
export const POOL_IDS = {
  aqru: '0xe9d33286f0e37f517b1204aa6da085564414996d',
  cashUSDCBase: '0xdd5bb9acf5e02089735a33344c6e3a8bb0d4075d',
  cashUSDC: '0xfe119e9c24ab79f1bdd5dd884b86ceea2ee75d92',
  cashUSDT: '0xf05681a33a9adf14076990789a89ab3da3f6b536',
  cicada: '0xf025edfa685c9ea873ea4b22da85e7e1fba24381',
  highYieldWETH: '0xccbc525ed9d85ad8325b7b6c4c6a79f5566dea3b',
  highYieldUSDC1: '0xc39a5a616f0ad1ff45077fa2de3f79ab8eb8b8b9',
  icebreaker: '0x137f2ea5cfb0fe59408bab2779e33ee868f1810e',
  mapleDirect: '0xc1dd3f011290f212227170f0d02f511ebf57e433',
  mavenUSDC: '0x00e0c1ea2085e30e5233e98cfa940ca8cbb1b0b7',
  mavenUSDCOld: '0xd2b01f8327eeca47829efc731f1a89c6d07e6b92',
  mavenUSDCPermissionless: '0xd3cd37a7299b963bbc69592e5ba933388f70dc88',
  mavenWETHPermissionless: '0xfff9a1caf78b2e5b0a49355a8637ea78b43fb6c3',
  opportunistic: '0x6174a27160f4d7885db4ffed1c0b5fbd66c87f3a',
  orthogonal: '0x79400a2c9a5e2431419cac98bf46893c86e8bdd7',
};

const SYRUP_POOL_IDS = {
  'mainnet-prod': '0x80ac24aa929eaf5013f6436cda2a7ba190f5cc0b',
  'sepolia-dev': '0xaa45e2cd38b1733b1cfe375f9d62a4f046660600',
  localhost: '0x992315d1288e87e8110e26aeca628502bc0a9c52',
};

export const SYRUP_IDS = Object.values(SYRUP_POOL_IDS);

export const impairedLossesGitbookLink =
  'https://maplefinance.gitbook.io/maple/maple-for-lenders/defaults-and-impairments';

export const REDIRECT_PATHS: { [key: string]: string } = {
  '/v2/lend/pool/0x228b210885e233cb538808900f50e054bdd61e54':
    '/v2/lend/pool/0xe9d33286f0e37f517b1204aa6da085564414996d', // AQRU old pool -> new pool
};

export const COLLATERAL_OVERIDE: { [borrower: string]: any } = {
  // R40-T1 LLC CIRCLE
  '0xda28e780472b5754a1144bce6e83af06a33107d2': {
    acmRatio: '1000000', // 100%
    asset: 'US T-Bill',
  },
};

// ValueInterface constants
export const ZERO_USDC = {
  formatted: '0.00',
  bigNumber: BigNumber.from(0),
  parsed: '0',
};

export const EMPTY_PERCENTAGE = { formatted: '-', bigNumber: ZERO, parsed: '0' };

export const MPLV2_ENABLED: boolean = process.env.REACT_APP_MPLV2_ENABLED
  ? JSON.parse(process.env.REACT_APP_MPLV2_ENABLED)
  : false;

export const MPLV1_TOTAL_SUPPLY = '10000000';

export const SECURITIZE_ISSUER_ID = process.env.REACT_APP_SECURITIZE_ID;

export const COURIER_CLIENT_KEY = process.env.REACT_APP_COURIER_CLIENT_KEY;

export const NETWORK_SELECTOR_ENABLED: boolean = process.env.REACT_APP_NETWORK_SELECTOR_ENABLED
  ? JSON.parse(process.env.REACT_APP_NETWORK_SELECTOR_ENABLED)
  : false;

export const isLenderDashboardChartsEnabled = process.env.REACT_APP_LENDER_DASHBOARD_CHARTS_ENABLED
  ? JSON.parse(process.env.REACT_APP_LENDER_DASHBOARD_CHARTS_ENABLED)
  : false;

export const PERMISSION_LEVELS = {
  PRIVATE: 0,
  FUNCTION_LEVEL: 1,
  POOL_LEVEL: 2,
  PUBLIC: 3,
};
